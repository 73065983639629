import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { isNull, isEmpty } from 'lodash';

const styles = theme => ({
  root: {
    display: 'block',
    marginBottom: '24px',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  title: {
    flexBasis: '40%',
    marginBottom: '16px',
  },
  value: {
    ...theme.typography.descriptionRow,
  },
});

function DescriptionRow({ classes, title, value }) {
  if (isNull(value) || isEmpty(value)) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {value}
      </Typography>
    </div>
  );
}

DescriptionRow.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DescriptionRow.defaultProps = {
  classes: {},
  className: null,
  title: '',
  value: null,
};

export default withStyles(styles)(DescriptionRow);
