/* eslint-disable max-len */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Footer from '../../../components/layout/Footer';
import ProductInfo from '../../../components/page/ProductInfo';
import RelatedArticlePages from '../../../components/page/RelatedArticlePages';
import RelatedRecipePages from '../../../components/page/RelatedRecipePages';
import ReactAndShare from '../../../components/page/ReactAndShare';
import ProductSeoFields from '../../../components/shared/Products/ProductSeoFields';
import ProductStructuredData from '../../../components/page/ProductStructuredData';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';
import PageBrowseTracker from '../../../components/page/PageBrowseTracker';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';

import { viewProduct } from '../../../utils/analyticUtils';

const styles = theme => ({
  root: {
    display: 'block',
    ...theme.palette.pageTemplates.otherPageTemplates,
    '@media print': {
      paddingTop: '60px',
    },
    '& #frosmoPlaceholderAfterProductReviews, & #frosmoPlaceholderBeforeFooter': {
      '@media print': {
        display: 'none',
      },
    },
  },
});

function ProductPageTemplate({ classes, data }) {
  const pageData = get(data, 'magentoProduct');
  const analytics = get(pageData, 'mainTheme.analytics');

  useEffect(() => {
    viewProduct(pageData);
  }, [pageData]);

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <ProductSeoFields pageData={pageData} />
      <ProductStructuredData pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <ProductInfo pageData={pageData} />
        <div id="frosmoPlaceholderAfterProductReviews" />
        <ReactAndShare pageData={pageData} />
        <RelatedArticlePages pageData={pageData} className={classes.relatedPages} />
        <RelatedRecipePages pageData={pageData} className={classes.relatedPages} />
        <div id="frosmoPlaceholderBeforeFooter" />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

ProductPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

ProductPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export const pageQuery = graphql`
  query ProductPageTemplate($pageId: String!) {
    magentoProduct(id: { eq: $pageId }) {
      id
      name
      sku
      fullPath
      languageCode
      node_locale
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      relatedArticlePages {
        ...RelatedArticlePageFragment
      }
      relatedRecipePages {
        ...RelatedRecipePageFragment
      }
      heroImage {
        ...ProductImageFragment
      }
      attributes {
        ...ProductAttributesFragment
      }
      brands {
        ...ProductBrandFragment
      }
      categories {
        ...ProductCategoryFragment
      }
      countryCategories {
        ...ProductCategoryFragment
      }
      foodRecommendationCategories {
        ...ProductCategoryFragment
      }
      tasteStyleCategories {
        ...ProductCategoryFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default withSecondaryTheme(withStyles(styles)(ProductPageTemplate));
