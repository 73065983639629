import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { Divider, Typography, withStyles, Container } from '@material-ui/core';
import { productDefaultImage } from '../../../../constants';
import ProductHeroImage from '../../../shared/Products/ProductHeroImage';
import ProductCategories from '../../../shared/Products/ProductCategories';
import ProductMonopolyLink from '../../../shared/Products/ProductMonopolyLink';
import ProductMonopolyPrice from '../../../shared/Products/ProductMonopolyPrice';
import ProductTasteStyleCategories from '../../../shared/Products/ProductTasteStyleCategories';
import ProductFoodRecommendationCategories from '../../../shared/Products/ProductFoodRecommendationCategories';
import ProductReviews from '../../ProductReviews';

import ProductMemoryListButton from '../../../shared/Products/ProductMemoryListButton';
import PrintButton from '../../../shared/PrintButton';
import ProductMainInfo from '../../ProductMainInfo';
import ProductRetailLinks from '../../../shared/Products/ProductRetailLinks';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    '@media print': {
      display: 'block',
      position: 'relative',
    },
  },
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  imageSide: {
    display: 'flex',
    justifyContent: 'center',
    flexBasis: '100%',
    maxWidth: '100%',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
      maxWidth: '50%',
      paddingTop: theme.spacing(0),
    },
    '@media print': {
      justifyContent: 'flex-end',
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  printButton: {
    display: 'none',
    float: 'right',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  name: {
    marginBottom: theme.spacing(2),
  },
  informations: {
    flexBasis: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
    '@media print': {
      paddingTop: '24px',
      position: 'absolute',
      top: '0',
      left: '0',
      maxWidth: '100%',
    },
  },
  basicInformation: {
    padding: theme.spacing(4, 2),
    backgroundColor: theme.palette.productPage.informationBackground,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
    '@media print': {
      backgroundColor: 'transparent',
      color: 'black',
      maxWidth: '50%',
      padding: '0 16px 0 0',
      minHeight: '340px',
      [theme.breakpoints.up('md')]: {
        marginTop: '0',
      },
    },
  },
  mainInformation: {},
  category: {
    backgroundColor: theme.palette.common.white,
  },
  divider: {
    height: '2px',
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.common.white,
    '@media print': {
      display: 'none',
    },
  },
  tasteStyleCategories: {
    marginBottom: theme.spacing(2),
  },
  foodRecommendationCategories: {
    marginBottom: theme.spacing(2),
  },
  monopolyPrice: {
    marginBottom: theme.spacing(2),
    minHeight: 0,
  },
  monopolyLink: {
    marginBottom: theme.spacing(2),
  },
  shortDescription: {
    marginBottom: theme.spacing(2),
  },
  memoryListButton: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.red,
  },
});

function ProductInfo({ className, classes, pageData }) {
  return (
    <>
      <Container className={classes.container}>
        <div className={clsx(classes.root, className)}>
          <div className={classes.imageSide}>
            <ProductHeroImage productData={pageData} defaultImage={productDefaultImage.Viinimaa} />
          </div>
          <div className={classes.informations}>
            <div className={classes.basicInformation}>
              <Typography variant="h1" className={classes.name}>
                {get(pageData, 'name')}
              </Typography>
              <ProductCategories productData={pageData} />
              <Divider className={classes.divider} />
              <ProductTasteStyleCategories productData={pageData} className={classes.tasteStyleCategories} />
              <ProductFoodRecommendationCategories
                productData={pageData}
                className={classes.foodRecommendationCategories}
              />
              <ProductMonopolyPrice productData={pageData} className={classes.monopolyPrice} variant="h2" />
              <ProductMonopolyLink productData={pageData} location="productPage" className={classes.monopolyLink} />
              <ProductRetailLinks productData={pageData} className={classes.monopolyLink} />
              <ProductMemoryListButton productData={pageData} className={classes.memoryListButton} variant="text" />
              <Typography variant="body1" className={classes.shortDescription}>
                {get(pageData, 'attributes.short_description.value')}
              </Typography>
            </div>
            <PrintButton className={classes.printButton} />
            <ProductMainInfo pageData={pageData} />
          </div>
        </div>
      </Container>
      <ProductReviews pageData={pageData} />
    </>
  );
}

ProductInfo.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductInfo.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ProductInfo);

export const query = graphql`
  fragment ProductBrandFragment on ContentfulBrand {
    id
    title
    fullPath
  }

  fragment ProductCategoryFragment on ContentfulCategory {
    id
    title
    fullPath
    categoryIcon {
      id
      cloudinaryImage {
        public_id
      }
      imageAltText
      imageTitleText
    }
  }

  fragment ProductImageFragment on MagentoProductImage {
    imageAltText
    imageTitleText
    cloudinaryImage {
      public_id
      secure_url
    }
  }

  fragment ProductAttributeFragment on MagentoProductAttribute {
    label
    value
  }

  fragment ProductAttributesFragment on MagentoProductAttributes {
    wine_colour {
      ...ProductAttributeFragment
    }
    how_to_use {
      ...ProductAttributeFragment
    }
    aroma {
      ...ProductAttributeFragment
    }
    country_of_origin {
      ...ProductAttributeFragment
    }
    product_classification {
      ...ProductAttributeFragment
    }
    description {
      ...ProductAttributeFragment
    }
    short_description {
      ...ProductAttributeFragment
    }
    base_unit {
      ...ProductAttributeFragment
    }
    volume {
      ...ProductAttributeFragment
    }
    alcohol_vol {
      ...ProductAttributeFragment
    }
    awards {
      ...ProductAttributeFragment
    }
    brand_ecomm {
      ...ProductAttributeFragment
    }
    closure {
      ...ProductAttributeFragment
    }
    packaging_type {
      ...ProductAttributeFragment
    }
    packing_material {
      ...ProductAttributeFragment
    }
    taste {
      ...ProductAttributeFragment
    }
    subregion {
      ...ProductAttributeFragment
    }
    sugar_content {
      ...ProductAttributeFragment
    }
    grapes {
      ...ProductAttributeFragment
    }
    storage_information {
      ...ProductAttributeFragment
    }
    story {
      ...ProductAttributeFragment
    }
    sweetness {
      ...ProductAttributeFragment
    }
    how_its_made {
      ...ProductAttributeFragment
    }
    monopoly_number {
      ...ProductAttributeFragment
    }
    monopoly_price {
      ...ProductAttributeFragment
    }
    alko_taste_styles {
      ...ProductAttributeFragment
    }
    alko_food_recommendations {
      ...ProductAttributeFragment
    }
    viinimaa_labels {
      ...ProductAttributeFragment
    }
    alcohol_type {
      ...ProductAttributeFragment
    }
    monopoly_url {
      ...ProductAttributeFragment
    }
    monopoly_reduced_price {
      ...ProductAttributeFragment
    }
    folk_o_folk_food_recommendations {
      ...ProductAttributeFragment
    }
    folk_o_folk_labels {
      ...ProductAttributeFragment
    }
    fullbodiness {
      ...ProductAttributeFragment
    }
    vintage {
      ...ProductAttributeFragment
    }
    review_data {
      ...ProductAttributeFragment
    }
    monopoly_sortiment {
      ...ContentProductAttributeFragment
    }
    debosit {
      ...ContentProductAttributeFragment
    }
    retails_urls {
      ...ContentProductAttributeFragment
    }
    brand_ecomm {
      ...ContentProductAttributeFragment
    }
  }
`;
