import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductSeoFields from './viinimaa';
import KoskenkorvaProductSeoFields from './koskenkorva';

function ProductSeoFields(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductSeoFields {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductSeoFields {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaProductSeoFields {...props} />;
  }

  return null;
}

export default ProductSeoFields;
